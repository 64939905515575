import { useEffect, useState } from 'react';
import { getContent, getServerParams } from '../../services/content';
import { useParams } from 'react-router-dom';
import VideoIframe from '../video-iframe/VideoIframe';
import { IVideoList } from '../../interfaces/IVideoList';
import './SingleVideo.scss';

function SingleVideo() {
  const [content, setContent] = useState<IVideoList>();
  const { id } = useParams();
  const rewardedTagId = 1001920;

  useEffect(() => {
    window.scrollTo(0, 0);

    getContent().then((items) => {
      let d = items['video-list'].filter(
          (el: IVideoList) => el['video_id'] == id
      );
      d[0]['views'] += ' DOWNLOADS';

      setContent(d[0]);

      // @ts-ignore;
      if (rewardedTagId !== false) {
        getServerParams(rewardedTagId).then((params) => {
          const INCENTIVE_NEW_WINDOW_DOMAIN = params[13];
          const CLOUDFRONT_BACKUP_DOMAIN = params[4];

          const links = document.querySelectorAll('.video-description a');
          links.forEach((el, index) => {
            ['click', 'touchmove', 'mousedown'].forEach(function (action) {
              el.addEventListener(action, (ev) => {
                ev.preventDefault();
                ev.stopImmediatePropagation();

                const link = ev.target as HTMLAnchorElement;

                const serverParamsUrl =
                    'https://' +
                    INCENTIVE_NEW_WINDOW_DOMAIN +
                    '/?h=' +
                    encodeURIComponent(window.location.hostname) +
                    '&tid=' +
                    rewardedTagId +
                    '&cc=' +
                    encodeURIComponent(
                        window.btoa(
                            JSON.stringify({
                              title: encodeURIComponent(d[0]['title']),
                              cd: rewardedTagId,
                              domain: encodeURIComponent(CLOUDFRONT_BACKUP_DOMAIN),
                              link: encodeURIComponent(link['href']),
                            })
                        )
                    );
                window.open(serverParamsUrl);
              });
            });
          });
        });
      }
    });
  }, [id, rewardedTagId]);

  if (!content) {
    return <div className="single-video">Loading...</div>;
  }

  return (
      content && (
          <>
            <div className="single-video-wrapper">
              <div
                  key={content['embed'] + content['title']}
                  className="video-list-wrapper video-description-wrapper"
              >
                <div className="video-left-block">
                  {/* IFRAME */}
                  <VideoIframe embed={content['embed']} />
                  {/* END IFRAME */}
                </div>
                <br />
                <br />
                <div className="video-right-block">
                  <div className="video-title">{content['title']}</div>
                  <div className="video-downloads">{content['views']}</div>
                  <div
                      className="video-description"
                      dangerouslySetInnerHTML={{ __html: content['description'] }}
                  ></div>
                </div>
              </div>
            </div>
          </>
      )
  );
}

export default SingleVideo;
